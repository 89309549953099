import React, { Fragment } from 'react'
import MetisMenu from 'react-metismenu'
import { getUserMenu } from '../../Reducers/storageApp'

export default function AppNav() {
  const menu = getUserMenu()
  let menuRender
  menuRender = <Fragment>{menu?.length > 0 && <MetisMenu content={menu} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />}</Fragment>

  return <Fragment>{menuRender}</Fragment>
}
