import axios from 'axios'
import api from './api'
const URL_BASE = process.env.REACT_APP_API

export const GetTokenApp = async () => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/TokenApp`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: `"${process.env.REACT_APP_KEYAPI}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}

export const ContaGet = async (token) => {
  try {
    const req = await api.get('/Conta/GetContas')
    const json = req
    return json.data
  } catch (error) {
    return undefined
  }
}

export const LoginUsuario = async (token, value) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/Login`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const RecuperarSenha = async (email) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/RecuperarLogin`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(email),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const IsHashValido = async (hash) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/IsHashValido`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(hash),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const AltSenha = async (values) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/AltSenha`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(values),
    })
    const json = req.json()
    return json
  } catch (error) {
    return undefined
  }
}

//Usuario
export const UsuarioGet = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Usuario/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetById = async (Id) => {
  try {
    const req = await api.get(`/Usuario/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetAcessos = async () => {
  try {
    const req = await api.get(`/Usuario/GetAcessos`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetBispos = async (filter) => {
  try {
    const req = await api.post(`/Usuario/GetBispos`, `"${filter}"`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetConvite = async (id) => {
  try {
    const req = await api.get(`/Usuario/GetConvite?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Perfil
export const PerfilAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Perfil/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGet = async () => {
  try {
    const req = await api.get('/Perfil/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGetById = async (Id) => {
  try {
    const req = await api.get(`/Perfil/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGetFuncoes = async (perfilId) => {
  try {
    const req = await api.get(`/Perfil/GetFuncoes?perfilId=${perfilId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Praças
export const PracaGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Praca/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PracaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Praca/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PracaGetById = async (Id) => {
  try {
    const req = await api.get(`/Praca/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PracaGetUrlSpalla = async (Id) => {
  try {
    const req = await api.get(`/Praca/GetUrlSpalla?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PracaUpBoost = async (values) => {
  try {
    const req = await api.post('/Praca/UpBoost', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Banner
export const BannerAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Banner/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const BannerGetAll = async () => {
  try {
    const req = await api.get('/Banner/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const BannerGetById = async (Id) => {
  try {
    const req = await api.get(`/Banner/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Programa
export const ProgramaGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Programa/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProgramaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Programa/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProgramaGetById = async (Id) => {
  try {
    const req = await api.get(`/Programa/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Postagem
export const PostagemGet = async () => {
  try {
    const req = await api.get('/Postagem/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Postagem/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemGetIdsByFilter = async (filters) => {
  try {
    const req = await api.post('/Postagem/GetIdsByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemGetDetails = async (filters) => {
  try {
    const req = await api.post('/Postagem/GetDetails', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemInativarById = async (filters) => {
  try {
    const req = await api.post('/Postagem/InativarById', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Postagem/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemUpdateSituacaoEmLote = async (values) => {
  try {
    const req = await api.post('/Postagem/UpdateSituacaoEmLote ', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemAddAsync = async (formData) => {
  try {
    const req = await api.post('/Postagem/AddAsync', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemGetById = async (Id) => {
  try {
    const req = await api.get(`/Postagem/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PostagemDelById = async (Id) => {
  try {
    const req = await api.delete(`/Postagem/DelById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysArquivo
export const SysArquivoUpload = async (formData) => {
  try {
    const req = await api.post('/SysArquivo/AddOrUpdate', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysArquivoGetById = async (Id) => {
  try {
    const req = await api.get(`/SysArquivo/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
//SysParametros
export const SysParametroGet = async () => {
  try {
    const req = await api.get(`/SysParametro/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysParametroAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysParametro/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//LogAcesso

export const LogAcessoGetReport = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetLogAcesso_Report', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

//LogAcesso - Dashboard
export const LogAcessoGetTempoMedioTotal = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetTempoMedioTotal', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetTempoMedioTotalGeral = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetTempoMedioTotalGeral', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetAcessosPorHora = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetAcessosPorHora', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetAcessosPorDia = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetAcessosPorDia', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetAcessosPorVersaoApp = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetAcessosPorVersaoApp', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetAcessosPorPlataforma = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetAcessosPorPlataforma', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetAcessosPorPraca = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetAcessosPorPraca', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetEvolucaoPosts = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetEvolucaoPosts', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LogAcessoGetTotaisPosts = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetTotaisPosts', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Termo
export const TermoUpdate = async (values) => {
  try {
    const req = await api.post('/Termo/Update', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TermoGet = async () => {
  try {
    const req = await api.get('/Termo/Get')
    const json = req
    return json.data
  } catch (error) {
    return undefined
  }
}
export const TermoAceiteUsuario = async (tokenUser, values) => {
  try {
    const req = await fetch(`${URL_BASE}/Termo/AceiteUsuario`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${tokenUser}` },
      body: JSON.stringify(values),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const GetIpClient = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json')
    return response
  } catch (error) {
    return undefined
  }
}

//SysLog
export const SysLogGetByService = async () => {
  try {
    const req = await api.get('/SysLog/GetByService')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysLogGet = async (filters) => {
  try {
    const req = await api.post('/SysLog/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysLogGetProcessos = async (filters) => {
  try {
    const req = await api.post('/SysLog/GetProcessos', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
