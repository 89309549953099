import { Route, Navigate, Routes, HashRouter } from 'react-router-dom'
import React, { Suspense, lazy, Fragment } from 'react'
import { ToastContainer } from 'react-toastify'
import { getToken } from '../../Reducers/storageApp'

import Login from '../../Pages/Login'
import Home from '../../Pages/Home'

const Settings = lazy(() => import('../../Pages/Settings'))
const Square = lazy(() => import('../../Pages/Square'))
const Program = lazy(() => import('../../Pages/Program'))
const Post = lazy(() => import('../../Pages/Post'))
const RelAcessos = lazy(() => import('../../Pages/RelAcessos'))
const Termo = lazy(() => import('../../Pages/Termo'))

const Boost = lazy(() => import('../../Pages/Boost'))
const SysMonitor = lazy(() => import('../../Pages/SysMonitor'))

const PrivateRoute = ({ component: Component }) => {
  const token = getToken()
  if (!token) return (window.location.href = '/#/Login')
  return Component
}

export default function App() {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Aguarde enquanto carregamos todos os componentes
                <small> ... </small>
              </h6>
            </div>
          </div>
        }
      >
        <HashRouter>
          <Routes>
            <Route path="/" exact element={<PrivateRoute component={<Home />} />} />
            <Route path="/Home" element={<PrivateRoute component={<Home />} />} />
            <Route path="/Square/*" element={<PrivateRoute component={<Square />} />} />
            <Route path="/Program/*" element={<PrivateRoute component={<Program />} />} />
            <Route path="/Post/*" element={<PrivateRoute component={<Post />} />} />
            <Route path="/RelAcessos/*" element={<PrivateRoute component={<RelAcessos />} />} />
            <Route path="/Termo/*" element={<PrivateRoute component={<Termo />} />} />
            <Route path="/Boost" element={<PrivateRoute component={<Boost />} />} />
            <Route path="/SysMonitor" element={<PrivateRoute component={<SysMonitor />} />} />
            <Route path="/Settings/*" element={<PrivateRoute component={<Settings />} />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<PrivateRoute component={<Navigate to="/Home" />} />} />
            <Route path="/login/*" element={<Login />} />
          </Routes>
        </HashRouter>
      </Suspense>

      <ToastContainer />
    </Fragment>
  )
}
