import React, { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import CountUp from 'react-countup'
import { LoadingCard, MsgSemRegistros, moment } from '../../../../Components'
import { LogAcessoGetAcessosPorHora } from '../../../../Service/ApiService'
import { Card, CardBody, Col } from 'reactstrap'
import { MdOutlineAirplay } from 'react-icons/md'
import { LabelList, Line, LineChart, ResponsiveContainer } from 'recharts'

const AccessLastHourCard = ({ filters }) => {
  const [confChart, setConfChart] = useState()
  const [values, setValues] = useState({ qtde: 0, horaPico: '' })
  const [msgId, setMsgId] = useState(1)

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetAcessosPorHora(filters)
      if (response?.length > 0) {
        const currentHour = moment().hour().toString().padStart(2, '0')
        const accessCount = response.find((item) => item.hora === currentHour)?.qtdeHora || 0
        let peakHourData = { hora: '00', qtdeHora: 0 }
        response.forEach((item) => {
          if (item.qtdeHora > peakHourData.qtdeHora) peakHourData = item
        })

        setValues({ qtde: accessCount, qtdeHora: peakHourData.qtdeHora, horaPico: `${peakHourData.hora}h e ${(parseInt(peakHourData.hora) + 1).toString().padStart(2, '0')}h` })

        let _confChart = []
        response.forEach((d) => {
          _confChart.push({ name: d.hora + ':00', pv: d.qtdeHora })
        })

        setConfChart(_confChart)

        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  return (
    <>
      {
        {
          1: (
            <Col md="12" className="text-center">
              <LoadingCard isVisible={true} />
            </Col>
          ),
          2: (
            <div className="card mb-2 bg-love-kiss widget-chart card-border" style={{ zIndex: '0' }}>
              <div className="widget-chart-content text-white">
                <div className="icon-wrapper rounded-circle" style={{ display: 'flex', justifyContent: 'center' }}>
                  <MdOutlineAirplay color="#fff" size={32} />
                  <div className="icon-wrapper-bg bg-white opacity-4" />
                </div>
                <div className="widget-numbers">
                  <CountUp end={values.qtde} duration={3} />
                </div>
                <div className="widget-subheading">Quantidade de acessos na última hora</div>
                <div className="widget-description">
                  <FaArrowRight />
                  <span className="text-white">
                    Pico entre {values.horaPico} com {values.qtdeHora} acessos
                  </span>
                </div>
              </div>
              <div className="widget-chart-wrapper" style={{ height: '100%', width: '100%', objectFit: 'cover' }}>
                <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                  <LineChart data={confChart} margin={{ top: 0, right: 5, left: 5, bottom: 0 }}>
                    <Line type="monotone" dataKey="pv" stroke="#ffffff" strokeWidth={3}>
                      <LabelList dataKey="name" position="top" />
                    </Line>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          ),
          3: (
            <Card className="main-card mb-2" style={{ height: '110px' }}>
              <CardBody>
                <MsgSemRegistros />
              </CardBody>
            </Card>
          ),
        }[msgId]
      }
    </>
  )
}

export default AccessLastHourCard
