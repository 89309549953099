import { Fragment, useState } from 'react'
import { BtnPlay, CardPlay, Container, Preview } from './styles'
import { BiPlayCircle } from 'react-icons/bi'

export default function FormEdit({ srcMidia }) {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <Container>
      {!showVideo ? (
        <Fragment>
          <Preview src={srcMidia?.substr(0, srcMidia?.lastIndexOf('.')) + '.png'} alt="" />
          <CardPlay>
            <BtnPlay onClick={() => setShowVideo(true)}>
              <BiPlayCircle size={52} color="#fff" />
            </BtnPlay>
          </CardPlay>
        </Fragment>
      ) : (
        <video autoPlay controls src={srcMidia} style={{ width: '100%' }} preload="true" poster={srcMidia?.substr(0, srcMidia?.lastIndexOf('.')) + '.png'} />
      )}
    </Container>
  )
}
