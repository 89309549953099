import Lottie from 'react-lottie'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ReactCSSTransitionGroup } from '../../../Components'

import animationData from './welcome2.json'

export default function Welcome() {
  const defaultOptions = { loop: true, autoplay: true, animationData: animationData }

  return (
    <div className="page-content" style={{ height: 'calc(100vh - 130px)', display: 'flex' }}>
      <div style={{ margin: 'auto 0', width: '100%' }}>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <Card className="main-card mb-2">
            <CardBody style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <h1> Seja bem-vindo(a) a rede CNT</h1>
                </Col>
                <Col md={12}>
                  <Lottie options={defaultOptions} style={{ maxHeight: '600px', maxWidth: '700px' }} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </div>
    </div>
  )
}
