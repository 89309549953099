import React, { useEffect, useState } from 'react'
import { Main } from './style'
import { FaDisplay } from 'react-icons/fa6'
import { LoadingCard } from '../../../../Components'
import { LogAcessoGetTempoMedioTotalGeral } from '../../../../Service/ApiService'
import { Col } from 'reactstrap'
import { BsCollectionPlay } from 'react-icons/bs'
import { normaliseValue } from '../../../../helpers/util'

const AverageViewTimeCard = ({ filters }) => {
  const [msgId, setMsgId] = useState(1)
  const [values, setValues] = useState({ media: 0, total: 0 })

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetTempoMedioTotalGeral(filters)
      if (response) {
        setValues(response)

        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  function formatHoras(minutos) {
    const horas = Math.floor(minutos / 60)
    const min = minutos % 60

    const textoHoras = horas < 10 ? `00${horas}`.slice(-2) : normaliseValue(horas, 0)
    const textoMinutos = `00${min}`.slice(-2)

    return `${textoHoras}:${textoMinutos}`
  }

  return (
    <>
      {
        {
          1: (
            <Col md="12" className="text-center">
              <LoadingCard isVisible={true} />
            </Col>
          ),
          2: (
            <>
              <Main>
                <Col md={6}>
                  <div className="card mb-2 bg-midnight-bloom widget-chart text-white card-border">
                    <div className="icon-wrapper rounded" style={{ display: 'flex', justifyContent: 'center' }}>
                      <FaDisplay color="#ff9a9e" size={32} style={{ zIndex: '9' }} />
                      <div className="icon-wrapper-bg bg-white opacity-10" />
                    </div>
                    <div className="widget-numbers">{formatHoras(values.media)}</div>
                    <div className="widget-subheading">Média de horas</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="card mb-2 bg-arielle-smile widget-chart text-white card-border">
                    <div className="icon-wrapper rounded-circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <BsCollectionPlay color="#46aef7" size={32} style={{ zIndex: '9' }} />
                      <div className="icon-wrapper-bg bg-white opacity-10" />
                    </div>
                    <div className="widget-numbers">{formatHoras(values.total)}</div>
                    <div className="widget-subheading">Horas totais</div>
                  </div>
                </Col>
              </Main>
            </>
          ),
          3: <></>,
        }[msgId]
      }
    </>
  )
}

export default AverageViewTimeCard
