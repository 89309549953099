import { CardBody } from 'reactstrap'
import styled from 'styled-components'

export const ItemBody = styled(CardBody)`
  padding: 0, 75rem 0.25rem !important;
`

export const ItemTitle = styled.div`
  margin-bottom: 10px !important;
`
export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  font-weight: 400;
  font-size: 1.1rem;

  color: rgba(0, 0, 0, 0.7);

  border-bottom: 1px solid rgba(26, 54, 126, 0.125);
  padding: 0 0 10px 10px;
`
