const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (!serializedState) {
      return {
        token: {
          value: '',
          expiration: '',
        },
        user: {
          id: '',
          name: '',
          perfil: '',
          contaId: '',
        },
        userLogState: '',
      }
    } else {
      return JSON.parse(serializedState)
      //Prod
      // var atob = require('atob');
      // var stateObj = JSON.parse(serializedState);
      // let decoded = atob(stateObj);
      // return decoded;
    }
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
    //Prod
    // let encoded = btoa(serializedState);
    // localStorage.setItem('state', encoded);
  } catch (err) {}
}

export const getToken = () => {
  try {
    var state = loadState()
    const token = state.token

    var moment = require('moment')
    if (!token?.expiration || new Date(token?.expiration) < moment.utc()) {
      putToken()
      return undefined
    }
    return token.value
  } catch {
    return undefined
  }
}
export const putToken = (token, expiration) => {
  try {
    var state = loadState()
    if (token) state = { ...state, token: { value: token, expiration } }
    else state = { ...state, token: undefined }
    saveState(state)
  } catch {}
}

export const getUser = () => {
  try {
    var state = loadState()
    return state.user
  } catch {
    return undefined
  }
}
export const putUser = (values) => {
  try {
    var state = loadState()
    if (values) state = { ...state, user: { ...state.user, ...values } }
    else state = { ...state, user: undefined }
    saveState(state)
  } catch {}
}
export const getUserLogState = () => {
  try {
    var state = loadState()
    let result = undefined
    if (state.userLogState) {
      var atob = require('atob')
      result = atob(state.userLogState)
    }
    return result
  } catch {
    return undefined
  }
}
export const putUserLogState = (value) => {
  try {
    var state = loadState()
    if (value) state = { ...state, userLogState: btoa(value) }
    else state = { ...state, user: undefined }
    saveState(state)
  } catch {}
}

export const getUserAccess = () => {
  try {
    var state = loadState()
    return state.user?.access
  } catch {
    return undefined
  }
}
export const putUserAccessMenu = async (access, menu) => {
  try {
    var state = loadState()

    state = { ...state, user: { ...state.user, access, menu } }

    saveState(state)
  } catch {}
}
export const getUserMenu = () => {
  try {
    var state = loadState()
    return state.user?.menu
  } catch {
    return undefined
  }
}
