export function abbreviateNumber(number) {
  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

  var tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier == 0) return number
  var suffix = SI_SYMBOL[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  return scaled.toFixed(1) + suffix
}

export function normaliseValue(value, decimals = 2) {
  if (!value) return ''

  if (value === '.') return '0.'

  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
  const decimalsNumber = value.toString().match(regex)[0]

  let parsed = parseFloat(decimalsNumber).toFixed(decimals)

  parsed = parsed.replace('.', ',')

  const parts = parsed.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return parts.join(',')
}
