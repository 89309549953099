import { getUserAccess } from '../Reducers/storageApp'

export function Biz_IsAccessCodigo(codigo) {
  const userAccess = getUserAccess()

  if (userAccess) return userAccess.some((f) => f.itens.some((i) => i.codigo === codigo))
  else return false
}

export function Biz_IsAccessGrupo(grupo) {
  const userAccess = getUserAccess()

  if (userAccess) return userAccess.some((f) => f.grupo == grupo)
  else return false
}
