import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
  border-radius: 5px;
`
export const Preview = styled.img`
  width: 100%;
`
export const CardPlay = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: 'rgba(0,0,0,0)';
`
export const BtnPlay = styled.div`
  border-radius: 50%;
  background-color: 'rgba(0,0,0,0.8)';
  :hover {
    cursor: pointer;
    transform: scale(1.5);
    transition: all 1s ease;
    opacity: 1;
  }
`
