import React, { useEffect, useState } from 'react'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import AppFooter from '../../Layout/AppFooter'

import Welcome from './Welcome'
import Dashboard from './Dashboard'
import { Biz_IsAccessCodigo } from '../../Service/BizService'

export default function Dashboards() {
  const [tpView, setTpView] = useState(0)

  useEffect(() => {
    if (Biz_IsAccessCodigo('Dashboard01') === true) setTpView(2)
    else setTpView(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            {tpView === 1 && <Welcome />}
            {tpView === 2 && <Dashboard />}
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}
