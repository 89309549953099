import React, { useEffect, useState } from 'react'
import { LoadingCard, MsgSemRegistros, ApexCharts } from '../../../../Components'
import { Card, CardBody, Col } from 'reactstrap'
import { CardTitle } from '../styles'
import { LogAcessoGetTempoMedioTotal } from '../../../../Service/ApiService'

let initChart = {
  series: [
    { name: 'Média', data: [], type: 'column' },
    { name: 'Total', data: [], type: 'line' },
  ],
  options: {
    chart: { height: 350, type: 'line', stacked: false, toolbar: { show: false } },
    stroke: { width: [1, 5] },
    plotOptions: { bar: { columnWidth: '50%' } },
    xaxis: { categories: [], type: 'datetime', labels: { format: 'dd MMM' } },
    yaxis: [
      { min: 0, seriesName: 'Média', show: true, title: { text: 'Minutos' } },
      { min: 0, seriesName: 'Total', opposite: true, show: false },
    ],

    tooltip: { shared: true, intersect: false, x: { format: 'dd MMM yyyy' } },
    colors: ['#f48024', '#33b2df', '#69d2e7'],
    responsive: [{ breakpoint: 450, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }],
  },
}

const DailyViewTimeCard = ({ filters }) => {
  const [confChart, setConfChart] = useState(initChart)
  const [msgId, setMsgId] = useState(1)

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetTempoMedioTotal(filters)
      if (response.labels?.length > 0) {
        let _confChart = { ...initChart }
        _confChart.series[0].data = response.media
        _confChart.series[1].data = response.total
        _confChart.options.xaxis.categories = response.labels

        setConfChart(_confChart)
        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  return (
    <Card className="main-card mb-2">
      <CardBody>
        <CardTitle>Tempo total e médio de exibição</CardTitle>

        {
          {
            1: (
              <Col md="12" className="text-center">
                <LoadingCard isVisible={true} />
              </Col>
            ),
            2: <ApexCharts options={confChart.options} series={confChart.series} type="line" height={260} />,
            3: <MsgSemRegistros />,
          }[msgId]
        }
      </CardBody>
    </Card>
  )
}

export default DailyViewTimeCard
