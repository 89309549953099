import styled from 'styled-components'

export const Title = styled.p`
  padding: 10px 0 0 0;
  font-size: 1rem;
`
export const ContainerForm = styled.form`
  width: 100%;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 1.2rem;
    line-height: 1.334;
    letter-spacing: 0em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: var(--gray-dark);
  }
`
export const Input = styled.input`
  width: 100%;
  height: 32px;
  margin: 10px 0;
  padding: 18.5px 14px;

  color: var(--gray-dark);
  border: 0.5px solid var(--gray-dark);
  border-radius: 4px;
  opacity: 1;
  font-size: 1rem;

  ::placeholder {
    opacity: 1;
  }
`
export const Button = styled.button`
  width: 100%;
  padding: 8px 0;

  margin: 10px 0 10px auto;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
  background: var(--bitys-blue) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 688px) {
    width: 100%;
  }
`
export const BoxTermo = styled.div`
  background-color: #e6e6e6;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  white-space: pre-wrap;
  overflow: auto;
  max-height: 65vh;
`
