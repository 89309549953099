import React, { useEffect, useState } from 'react'
import { Wrapper, Container, BoxIcon, CardValues, LbTitle, LbValor } from './styles'
import { BsFillHeartFill } from 'react-icons/bs'
import { FaBookOpen, FaEye } from 'react-icons/fa'
import { LoadingCard, MsgSemRegistros } from '../../../../Components'
import { LogAcessoGetTotaisPosts } from '../../../../Service/ApiService'
import { Card, CardBody, Col } from 'reactstrap'
import { normaliseValue } from '../../../../helpers/util'

export default function CardLikeView({ filters }) {
  const [values, setValues] = useState({})
  const [msgId, setMsgId] = useState(1)

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetTotaisPosts(filters)
      if (response) {
        setValues(response)
        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  return (
    <>
      <Card className="main-card mb-2">
        <CardBody>
          {
            {
              1: (
                <Col md="12" className="text-center">
                  <LoadingCard isVisible={true} />
                </Col>
              ),
              2: (
                <Wrapper>
                  <Container md={3}>
                    <BoxIcon style={{ backgroundColor: '#3ac47d' }}>
                      <FaBookOpen size={20} color="white" />
                    </BoxIcon>
                    <CardValues>
                      <LbTitle>Posts</LbTitle>
                      <LbValor>{normaliseValue(values.posts, 0)}</LbValor>
                    </CardValues>
                  </Container>
                  <Container md={3}>
                    <BoxIcon style={{ backgroundColor: 'rgb(0, 143, 251)' }}>
                      <FaEye size={20} color="white" />
                    </BoxIcon>
                    <CardValues>
                      <LbTitle>Views</LbTitle>
                      <LbValor>{normaliseValue(values.views, 0)}</LbValor>
                    </CardValues>
                  </Container>
                  <Container md={3}>
                    <BoxIcon style={{ backgroundColor: '#d92550' }}>
                      <BsFillHeartFill size={20} color="white" />
                    </BoxIcon>
                    <CardValues>
                      <LbTitle>Likes</LbTitle>
                      <LbValor>{normaliseValue(values.likes, 0)}</LbValor>
                    </CardValues>
                  </Container>
                </Wrapper>
              ),
              3: <MsgSemRegistros />,
            }[msgId]
          }
        </CardBody>
      </Card>
    </>
  )
}
