import React, { Fragment, useState } from 'react'
import { Loading } from '../../../Components'
import { GetIpClient, TermoAceiteUsuario } from '../../../Service/ApiService'
import { ContainerForm, Button, Title, BoxTermo } from './styles'

import { getUser, putToken } from '../../../Reducers/storageApp'
import { useSelector } from 'react-redux'
import { toast, Bounce } from 'react-toastify'

export default function Element() {
  const [isLoading, setIsLoading] = useState(false)
  const tempToken = useSelector((state) => state.tempToken)

  const user = getUser()

  async function onConcordo() {
    if (!tempToken.token) {
      toast('Token expirado! É necessário realizar o login!', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      window.location.href = '/#/Login'
      return
    }

    setIsLoading(true)

    let ipResult = await GetIpClient()
    let ipClient = ipResult?.data?.ip

    let _valuesAceite = {
      termoId: user?.termo?.termoId,
      usuarioId: user?.id,
      ip: ipClient,
    }

    let response = await TermoAceiteUsuario(tempToken.token, _valuesAceite)

    if (response.result) {
      putToken(tempToken.token, tempToken.expiration)
      window.location.href = '/#/Home'
    }
    setIsLoading(false)
    document.getElementById('boxTermo').innerHTML = '<p>Outro texto</p>'
  }

  return (
    <Fragment>
      <Loading isVisible={isLoading} />

      <ContainerForm>
        <Title>Termo de aceite! Você deve concordar com o termo abaixo para prosseguir!</Title>

        <BoxTermo id="boxTermo">{user?.termo?.conteudo}</BoxTermo>

        <Button type="button" onClick={onConcordo}>
          Concordo
        </Button>
      </ContainerForm>
    </Fragment>
  )
}
