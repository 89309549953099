import styled from 'styled-components'
import { CardHeader } from 'reactstrap'

export const Header = styled(CardHeader)`
  display: flex !important;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    > span {
      display: none;
    }
  }
`
