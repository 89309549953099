import React, { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { BsWindow } from 'react-icons/bs'
class PageTitle extends Component {
  render() {
    let {
      enablePageTitleIcon = true,
      enablePageTitleSubheading = true,

      heading,
      subheading,
      children,
    } = this.props

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx('page-title-icon', { 'd-none': !enablePageTitleIcon })}>
              <BsWindow size={24} style={{ margin: 'auto' }} color="var(--primary)" />
            </div>
            <div>
              {heading}
              <div className={cx('page-title-subheading', { 'd-none': !enablePageTitleSubheading })}>{subheading}</div>
            </div>
          </div>
          {children && <div className="page-title-actions">{children}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.enablePageTitleIcon,
  enablePageTitleSubheading: state.enablePageTitleSubheading,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle)
