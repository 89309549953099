import React, { useEffect, useState } from 'react'
import { ApexCharts, LoadingCard, MsgSemRegistros } from '../../../../Components'
import { Card, CardBody, Col } from 'reactstrap'
import { CardTitle } from '../styles'
import { LogAcessoGetAcessosPorPlataforma } from '../../../../Service/ApiService'

let initChart = {
  series: [],
  options: {
    chart: { type: 'pie', toolbar: { show: false } },
    labels: [],
    legend: { position: 'bottom' },
    colors: ['#69d2e7', '#f48024', '#13d8aa', '#db7087'],
    responsive: [{ breakpoint: 450, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }],
  },
}
const AccessByOSChart = ({ filters }) => {
  const [confChart, setConfChart] = useState(initChart)
  const [msgId, setMsgId] = useState(1)

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetAcessosPorPlataforma(filters)
      if (response.data?.length > 0) {
        let _confChart = { ...initChart }
        _confChart.series = response.data
        _confChart.options.labels = response.labels

        setConfChart(_confChart)
        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  return (
    <Card className="main-card mb-2">
      <CardBody>
        <CardTitle>Acessos por plataforma</CardTitle>
        {
          {
            1: (
              <Col md="12" className="text-center">
                <LoadingCard isVisible={true} />
              </Col>
            ),
            2: <ApexCharts options={confChart.options} series={confChart.series} type="pie" height={307} />,
            3: <MsgSemRegistros />,
          }[msgId]
        }
      </CardBody>
    </Card>
  )
}

export default AccessByOSChart
