import { Fragment, React, useEffect, useState } from 'react'
import Hamburger from 'react-hamburgers'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { Button } from 'reactstrap'
import { FaEllipsisV } from 'react-icons/fa'

export default function AppMobileMenu() {
  const dispatch = useDispatch()
  const enableMobileMenu = useSelector((state) => state.enableMobileMenu)
  const enableMobileMenuSmall = useSelector((state) => state.enableMobileMenuSmall)
  const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(false)
  const [activeMobile, setActiveMobile] = useState(false)

  function toggleMobileSidebar() {
    dispatch({ type: 'enableMobileMenu', value: !enableMobileMenu })
  }

  function toggleMobileSmall() {
    dispatch({ type: 'enableMobileMenuSmall', value: !enableMobileMenuSmall })
  }

  useEffect(() => {
    dispatch({ type: 'enableMobileMenu', value: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Hamburger active={enableMobileMenu} type="elastic" onClick={() => setActiveMobile(!activeMobile)} />
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button size="sm" className={cx('btn-icon btn-icon-only', { active: activeSecondaryMenuMobile })} color="primary" onClick={() => setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile)}>
            <div className="btn-icon-wrapper">
              <FaEllipsisV />
            </div>
          </Button>
        </span>
      </div>
    </Fragment>
  )
}
