import React, { useEffect, useState } from 'react'
import { ApexCharts, LoadingCard, MsgSemRegistros } from '../../../../Components'
import { Card, CardBody, Col } from 'reactstrap'
import { CardTitle } from '../styles'
import { LogAcessoGetAcessosPorPraca } from '../../../../Service/ApiService'

let initChart = {
  series: [{ name: 'Acessos', data: [] }],
  options: {
    chart: { type: 'bar', toolbar: { show: false } },
    xaxis: { categories: [] },
    yaxis: { labels: { formatter: (val) => Math.floor(val) } },
    colors: ['#13d8aa'],
    responsive: [{ breakpoint: 450, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }],
  },
}

const AccessByPracaChart = ({ filters }) => {
  const [confChart, setConfChart] = useState(initChart)
  const [msgId, setMsgId] = useState(1)

  async function loadData() {
    setMsgId(1)
    try {
      const response = await LogAcessoGetAcessosPorPraca(filters)
      if (response.data?.length > 0) {
        let _confChart = { ...initChart }
        _confChart.series = [{ data: response.data }]
        _confChart.options.xaxis.categories = response.labels

        setConfChart(_confChart)
        setMsgId(2)
      } else setMsgId(3)
    } catch {
      setMsgId(3)
    }
  }

  useEffect(() => {
    if (filters) loadData()
  }, [filters])

  return (
    <Card className="main-card mb-2">
      <CardBody>
        <CardTitle>Acessos por praça</CardTitle>

        {
          {
            1: (
              <Col md="12" className="text-center">
                <LoadingCard isVisible={true} />
              </Col>
            ),
            2: <ApexCharts options={confChart.options} series={confChart.series} type="bar" height={300} />,
            3: <MsgSemRegistros />,
          }[msgId]
        }
      </CardBody>
    </Card>
  )
}

export default AccessByPracaChart
