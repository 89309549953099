import React, { useState, useEffect } from 'react'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'
import { PracaGetByFilter } from '../../../Service/ApiService'

import { PageTitle, ReactCSSTransitionGroup, Select, toast, Bounce } from '../../../Components'
import AcessosPorDia from './AcessosPorDia'
import AcessosPorHora from './AcessosPorHora'
import TempoMedioTotal from './TempoMedioTotal'
import TempoMedioTotalGeral from './TempoMedioTotalGeral'
import AcessosPorVersaoApp from './AcessosPorVersaoApp'
import AcessosPorPlataforma from './AcessosPorPlataforma'
import AcessosPorPraca from './AcessosPorPraca'
import TotaisPosts from './TotaisPosts'
import EvolucaoPosts from './EvolucaoPosts'
import CardFilter from './CardFilter'

export default function Dashboards() {
  const [filters, setFilters] = useState()


  return (
    <>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Dashboard" subheading="Painel de indicadores de telespectadores por praça"></PageTitle>
          <div className="page-content">
            <Row>
              <Col lg="12">
                <CardFilter change={(ev) => setFilters(ev)} />
              </Col>

              <Col lg={7}>
                <AcessosPorDia filters={filters} />
              </Col>
              <Col lg={5}>
                <AcessosPorHora filters={filters} />
                <TempoMedioTotalGeral filters={filters} />
              </Col>

              <Col md={6}>
                <TempoMedioTotal filters={filters} />
              </Col>
              <Col md={3}>
                <AcessosPorVersaoApp filters={filters} />
              </Col>
              <Col md={3}>
                <AcessosPorPlataforma filters={filters} />
              </Col>

              <Col md={6}>
                <AcessosPorPraca filters={filters} />
              </Col>
              <Col md={6}>
                <TotaisPosts filters={filters} />
                <EvolucaoPosts filters={filters} />
              </Col>
            </Row>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
