import styled from 'styled-components'
import bgImagem from '../../assets/images/bg-image.jpg'

export const Wrapper = styled.div`
  width: 100vw;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${bgImagem}); // linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.5)), url(${bgImagem});
  background-position: center;
  background-size: cover;

  > div {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 688px) {
    min-height: 100vh;
  }

  @media (min-width: 689px) {
    height: 100vh;
  }
`
export const Filter = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const Container = styled.div`
  backdrop-filter: none;
  min-width: 328px;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;

  padding-bottom: 10px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);

  @media (max-width: 688px) {
    width: 90%;
    margin: 60px auto 20px;
  }
`
export const Title = styled.p`
  padding: 10px 0 0 0;
  font-size: 1rem;
`

export const CardLogo = styled.div`
  margin: -47px auto 8px;

  width: 80%;
  height: 94px;
  padding: 32px;
  text-align: center;
  opacity: 1;
  background: #e6e6e6;
  color: rgb(52, 71, 103);
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 0 0 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;

  > img {
    width: 200px;
  }
`
export const ContainerForm = styled.form`
  width: 100%;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 1.2rem;
    line-height: 1.334;
    letter-spacing: 0em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: var(--gray-dark);
  }
`
export const Input = styled.input`
  width: 100%;
  height: 32px;
  margin: 10px 0;
  padding: 18.5px 14px;

  color: var(--gray-dark);
  border: 0.5px solid var(--gray-dark);
  border-radius: 4px;
  opacity: 1;
  font-size: 1rem;

  ::placeholder {
    opacity: 1;
  }
`
export const ButtonShowPwd = styled.button`
  width: 35px;
  padding: 8px 0;
  height: 35px;

  margin: -47px 2px 10px auto;
  color: var(--gray-dark);
  background: transparent !important;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const Button = styled.button`
  width: 100%;
  padding: 8px 0;

  margin: 10px 0 10px auto;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
  background: var(--bitys-blue) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 688px) {
    width: 100%;
  }
`
export const Link = styled.a`
  color: inherit;
  font-size: 0.95rem;
  color: var(--blue);
  text-align: right;
`

export const CardLink = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--gray-dark);
  font-weight: 100;

  @media (max-width: 688px) {
    flex-direction: column;
    align-items: center;
  }
`
