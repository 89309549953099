import React from 'react'
import ReactDOM from 'react-dom'
import Main from './Layout/Main'

import './assets/custon.css'
import './assets/base.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'moment/locale/pt-br'

import { Provider } from 'react-redux'
import storeApp from './Reducers/storeApp'

ReactDOM.render(
  <Provider store={storeApp}>
    <Main />
  </Provider>,
  document.getElementById('root')
)
