import { Col } from 'reactstrap'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const Container = styled(Col)`
  padding: 0 2px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BoxIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0 2px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardValues = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4px;
`
export const LbTitle = styled.span`
  font-size: 12px;
  color: #6c757d;
`
export const LbValor = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-left: 3px;
`
